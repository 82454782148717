import React from "react";

import MainBanner from "../components/layout/MainBanner";

import mainBannerImage from "../assets/images/faith-apostolic-church-exterior.jpg";

const LandingSection = () => {
  return (
    <div id="home">
      <MainBanner
        bgImage={mainBannerImage}
        hasOverlay
        showLogo
        subtitle="Faith Apostolic Church of Jesus Christ (Hamilton) operates in
        accordance with the government guidelines regarding COVID-19"
        className="bg-full"
      />
    </div>
  );
};

export default LandingSection;
