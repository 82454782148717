const events = [
  {
    id: 0,
    title: "Example Event 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 1,
    title: "Example Event 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 2,
    title: "Example Event 3",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 3,
    title: "Example Event 4",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 4,
    title: "Example Event 5",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 5,
    title: "Example Event 6",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
  {
    id: 6,
    title: "Example Event 7",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: new Date().toLocaleDateString(),
    img: "",
  },
];

export default events;
