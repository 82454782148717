import React from "react";

import Title from "./layout/Title";

import bgImage from "../assets/images/faith-apostolic-church-sign.jpg";

import { Container, Row, Col } from "react-bootstrap";

const HoursSection = () => {
  const styles = {
    background: `url(${bgImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div id="hours">
      <Title text="Service Hours" color="primary" />

      <div style={styles}>
        <div className="bg-full">
          <Container fluid="sm">
            <div className="overlay-primary p-4 rounded">
              <Row>
                <Col lg className="mt-5 mb-5">
                  <h4 className="mb-4">Sunday</h4>

                  <h5>
                    Worship Service -{" "}
                    <span className="text-small">12:00pm</span>
                    <br />
                    <br />
                    Bible Teachings - <span className="text-small">6:00pm</span>
                  </h5>

                  <h6>- via Teleconference/Zoom</h6>
                </Col>
                <Col lg className="mt-5 mb-5">
                  <h4 className="mb-4">Wednesday</h4>

                  <h5>
                    Prayer Meeting - <span className="text-small">8:00pm</span>
                  </h5>
                </Col>
                <Col lg className="mt-5 mb-5">
                  <h4 className="mb-4">Friday</h4>

                  <h5>
                    Youth Service - <span className="text-small">7:30pm</span>
                  </h5>
                </Col>
                <Col lg className="mt-5 mb-5">
                  <h4 className="mb-4">Saturday</h4>

                  <h5>
                    Fasting Service -{" "}
                    <span className="text-small">11:00am</span>
                  </h5>

                  <h6>- once a month</h6>
                </Col>
              </Row>

              <Row>
                <Col lg className="mb-4">
                  <h5 className="text-center">
                    Teleconference Number: <u>867-292-3030</u>
                    <br />
                    Meeting ID is: <u>5022646</u>
                  </h5>
                </Col>

                <Col lg>
                  <h5 className="text-center">
                    Zoom Meeting Number: <u>918-121-7986</u>
                    <br />
                    Password is: <u>7F5vtq</u>
                  </h5>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default HoursSection;
