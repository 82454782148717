import React from "react";

import LandingSection from "../LandingSection";
import DepartmentsSection from "../DepartmentsSection";
import HoursSection from "../HoursSection";
import VideosSection from "../VideosSection";
import EventsSection from "../EventsSection";
import ContactSection from "../ContactSection";

const Home = () => {
  return (
    <>
      <LandingSection />
      <DepartmentsSection />
      <HoursSection />
      <VideosSection />
      <EventsSection />
      <ContactSection />
    </>
  );
};

export default Home;
