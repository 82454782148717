import React from "react";

import Title from "./layout/Title";

import { Container, Row, Col } from "react-bootstrap";

import background from "../assets/images/landing-section-image.jpg";

const style = {
  background: `url(${background})`,
  backgroundPosition: "left",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const DepartmentsSection = () => {
  return (
    <>
      <Title text="Departments" color="primary" />

      <div style={style}>
        <div className="overlay-light">
          <Container fluid>
            <Row>
              <Col lg className="mb-5">
                <h4>
                  <u>Outreach</u>
                </h4>

                <h5>
                  We seek to advance the Kingdom of God through witnessing and
                  interaction with members of various communities. The intent is
                  to bring the message of salvation to as many as possible.
                </h5>
              </Col>
              <Col lg className="mb-5">
                <h4>
                  <u>Brotherhood</u>
                </h4>

                <h5>
                  Our Brotherhood Department focuses on the development of young
                  men in the church; mentoring young men and leads in the
                  Outreach Ministry.
                </h5>
              </Col>
              <Col lg className="mb-5">
                <h4>
                  <u>Women's Auxiliary</u>
                </h4>

                <h5>
                  The Women's Auxiliary Department supports the ministry in
                  organizing special events, such as evangelistic services,
                  fundraising, health matters and maintenance of the Church.
                </h5>
              </Col>
              <Col lg className="mb-5">
                <h4>
                  <u>YP Department</u>
                </h4>

                <h5>
                  Our youth department strives to encourage young people in
                  serving the Lord. We deal with current issues, doctrine and
                  witnessing. Activities are geared to enhancing the development
                  of our youth to become stalwart leaders in the Apostolic
                  faith. We also focus with interacting with the youth in the
                  Community with intent to assist them in gaining knowledge
                  about Jesus Christ.
                </h5>
              </Col>
              <Col lg className="mb-5">
                <h4>
                  <u>Sunday School Department</u>
                </h4>

                <h5>
                  Our Sunday School aims to teach and to develop students in the
                  knowledge about God and His Kingdom.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default DepartmentsSection;
