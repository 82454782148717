import React, { useState } from "react";

import { LinkContainer } from "react-router-bootstrap";

import logo from "../../assets/logos/faith-logo.png";

import { Navbar, Nav, Image } from "react-bootstrap";

const Header = () => {
  const [active, setActive] = useState("home");

  const handleSelect = (section) => {
    const element = document.getElementById(section.replace(/[#, /]/gi, ""));

    if (element) {
      setActive(element.id);
      element.scrollIntoView();
    }
  };

  return (
    <Navbar
      onSelect={handleSelect}
      bg="primary"
      variant="dark"
      expand="md"
      sticky="top"
    >
      <Navbar.Brand>
        <LinkContainer to="/">
          <div>
            <Image
              fluid
              src={logo}
              style={{ width: "8rem" }}
              className="header-logo-image"
            />
          </div>
        </LinkContainer>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="main-navbar-nav" className="mt-3" />
      <Navbar.Collapse id="main-navbar-nav">
        <Nav className="ml-auto">
          <LinkContainer to="/#home">
            <Nav.Link className={active === "home" ? "active" : ""}>
              Home
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/#hours">
            <Nav.Link className={active === "hours" ? "active" : ""}>
              Service Hours
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/#videos">
            <Nav.Link className={active === "videos" ? "active" : ""}>
              Videos
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/#events">
            <Nav.Link className={active === "events" ? "active" : ""}>
              Events
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/#contact">
            <Nav.Link className={active === "contact" ? "active" : ""}>
              Contact
            </Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
