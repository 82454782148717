import React from "react";

import { Form, Button } from "react-bootstrap";

const ContactForm = () => {
  return (
    <>
      <h3 className="text-primary">Send a Message Here</h3>
      <Form>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Your Name" />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Your Email" />
        </Form.Group>

        <Form.Group controlId="message">
          <Form.Label>Email</Form.Label>
          <Form.Control as="textarea" rows="3" placeholder="Your Message" />
        </Form.Group>
        <Button variant="primary" className="text-light" type="submit">
          Send Message
        </Button>
      </Form>
    </>
  );
};

export default ContactForm;
