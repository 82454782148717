import React from "react";

import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="bg-primary pt-5 pb-5 text-light">
      <Container>
        <h4>Copyright &copy; Faith Apostolic Church 2020</h4>
        <h4>Designed By Zing Media</h4>
      </Container>
    </div>
  );
};

export default Footer;
