import React from "react";

const VideoItem = ({ img, title, date, description }) => {
  return (
    <div className="video-card">
      <div className="video-thumbnail">
        <img src={img} alt="" />
      </div>
      <div className="video-text">
        <div className="video-title">{title}</div>
        <div className="video-date">{date}</div>
        <div className="video-description">{description}</div>
      </div>
    </div>
  );
};

VideoItem.defaultProps = {
  img:
    "https://i.picsum.photos/id/370/500/300.jpg?hmac=yTEmvB5Tbd8lMCeVsf2RxPng3qRaZrqcaz9SwwFwsHg",
  title: "Lorem Ipsum",
  date: "Tuesday, September 17th, 2020",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quisque non tellus orci ac auctor. Ullamcorper morbi tincidunt ornare massa eget egestas purus. Sem integer vitae justo eget magna fermentum iaculis eu non. Urna neque viverra justo nec ultrices dui sapien eget. ",
};

export default VideoItem;
