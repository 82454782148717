import React from "react";

// import eventsData from "../utilities/eventsData";

// import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

// import EventItem from "./layout/EventItem";
import Title from "./layout/Title";

const EventsSection = () => {
  return (
    <div id="events">
      <Title text="Recent Events" color="primary" />

      <Container className="mt-5 mb-5">
        <h1 className="text-primary text-center">
          All events have been put on hold due to COVID-19
        </h1>

        <h1 className="text-primary text-center">Please check back later!</h1>
        {/* {eventsData.slice(0, 3).map((event) => (
          <EventItem
            key={event.id}
            id={event.id}
            title={event.title}
            text={event.description}
            date={event.date}
          />
        ))}

        <div className="text-center">
          <Link to="/events">
            <Button
              variant="primary"
              size="lg"
              style={{ color: "white", marginTop: "1rem", margin: "auto" }}
            >
              View All Events
            </Button>
          </Link>
        </div> */}
      </Container>
    </div>
  );
};

export default EventsSection;
