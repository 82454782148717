import React from "react";
import Title from "./layout/Title";

import VideoItem from "./layout/VideoItem";

import { Container, Button } from "react-bootstrap";

const VideosSection = () => {
  return (
    <div id="videos">
      <Title text="Latest Videos" color="primary" />

      <h3 className="text-primary text-center mt-5">No Videos Yet</h3>

      {/* <VideoItem />
      <VideoItem />
      <VideoItem />
      <VideoItem /> */}

      <Container fluid className="text-center p-5 mb-4">
        <Button variant="primary" size="lg" className="text-light">
          View All Videos
        </Button>
      </Container>
    </div>
  );
};

export default VideosSection;
