import React from "react";
import PropTypes from "prop-types";

import { Container } from "react-bootstrap";

import colors from "../../styling/variables.scss";

import logo from "../../assets/logos/faith-logo.png";

const MainBanner = ({
  title,
  subtitle,
  bgImage,
  showLogo,
  hasOverlay,
  overlay,
  className,
}) => {
  const styles = {
    background: bgImage ? `url(${bgImage})` : colors.primary,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div style={styles} className={className}>
      <div className={`text-center ${hasOverlay && overlay}`}>
        <Container>
          {showLogo && (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={logo}
              alt="Logo Image"
              style={{ width: "100%", maxWidth: "30rem" }}
            />
          )}
          <h1 className="mt-4">{title}</h1>
          <h3 className="p-4 bg-primary rounded">{subtitle}</h3>
        </Container>
      </div>
    </div>
  );
};

MainBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showLogo: PropTypes.bool,
  bgImage: PropTypes.string,
  hasOverlay: PropTypes.bool,
  overlay: PropTypes.string,
  className: PropTypes.string,
};

export default MainBanner;
