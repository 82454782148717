import React from "react";

//import { Link } from "react-router-dom";

import { Card, Button } from "react-bootstrap";

const EventItem = ({ title, text, date, id }) => {
  return (
    <Card className="event-item">
      <Card.Header className="events-item-header">{title}</Card.Header>
      <Card.Body>
        <blockquote className="events-item-text blockquote mb-0">
          <p>{text}</p>

          {/* <Link to={`/events/${id}`}> */}
          <Button variant="primary" size="sm" className="mb-3 text-light">
            View Event
          </Button>
          {/* </Link> */}

          <footer className="blockquote-footer">{date}</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
};

export default EventItem;
