import React, { useState, useEffect } from "react";

import eventsData from "../../utilities/eventsData";
import EventItem from "../layout/EventItem";

import Title from "../layout/Title";

import {
  Container,
  InputGroup,
  FormControl,
  Pagination,
} from "react-bootstrap";

const NUMBER_OF_ITEMS_PER_PAGE = 3;

const Events = () => {
  const [active, setActive] = useState(1);
  const [pages, setPages] = useState([]);
  //const [currentEvents, setCurrentEvents] = useState([]);

  const buildPagination = () => {
    const numberOfPages = Math.floor(
      eventsData.length / NUMBER_OF_ITEMS_PER_PAGE
    );

    const items = [];
    for (let i = 1; i <= numberOfPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === active}
          onClick={() => setActive(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    setPages(items);
    //setCurrentEvents(eventsData.slice(0, NUMBER_OF_ITEMS_PER_PAGE));
  };

  //const setActivePages = () => {};

  useEffect(() => {
    buildPagination();
    // eslint-disable-next-line
  }, [active]);

  return (
    <div>
      <Title text="Recent Events" color="primary" className="mt-4" />

      <Container className="mt-5 mb-5">
        <InputGroup className="mb-4">
          <FormControl
            placeholder="Search Events..."
            aria-label="events"
            aria-describedby="events"
          />
        </InputGroup>

        {eventsData.map((event) => (
          <EventItem
            key={event.id}
            id={event.id}
            title={event.title}
            text={event.description}
            date={event.date}
          />
        ))}

        <Pagination>{pages}</Pagination>
      </Container>
    </div>
  );
};

export default Events;
