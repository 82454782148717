import React from "react";
import "./styling/App.scss";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import Home from "./components/pages/Home";
import Events from "./components/pages/Events";
import EventPageItem from "./components/layout/EventPageItem";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Header />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:id" component={EventPageItem} />
      </Switch>

      <Footer />
    </Router>
  );
};

export default App;
