import React from "react";

import { BsPhone, BsEnvelope, BsGeoAlt } from "react-icons/bs";

import { Row, Col } from "react-bootstrap";

const ContactItems = () => {
  return (
    <>
      <Row className="mb-4">
        <Col xs={1}>
          <h3>
            <BsPhone />
          </h3>
        </Col>

        <Col xs={11}>
          <h3>
            <a className="contact-item-text" href="tel:905-549-6700">
              905-549-6700
            </a>
          </h3>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={1}>
          <h3>
            <BsEnvelope />
          </h3>
        </Col>
        <Col xs={11}>
          <h3>
            <a className="contact-item-text" href="mailto:fcjca@bellnet.ca">
              fcjca@bellnet.ca
            </a>
          </h3>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={1}>
          <h3>
            <BsGeoAlt className="mr-2" />
          </h3>
        </Col>
        <Col xs={11}>
          <h3>
            <a
              className="contact-item-text"
              href="https://www.google.com/maps/place/Faith+Church+of+Jesus+Christ/@43.2359702,-79.7919804,17z/data=!3m1!4b1!4m5!3m4!1s0x882c991ed7785989:0x151e1267f732427a!8m2!3d43.2359702!4d-79.7897917"
              target="_blank"
              rel="noopener noreferrer"
            >
              1855 Main St. E <br />
              Hamilton, Ontario, L8H 1G2
            </a>
          </h3>
        </Col>
      </Row>
    </>
  );
};

export default ContactItems;
