import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import Title from "./layout/Title";

import ContactItems from "./ContactItems";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  return (
    <div id="contact">
      <Title text="Contact" color="primary" />

      <Container className="mt-5 mb-5">
        <Row>
          <Col lg>
            <ContactItems />
          </Col>
          <Col lg>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
